.to-top-button {
  width: fit-content;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-color: transparent;
  border: none;
  background: transparent;
  margin: 0;
  z-index: 99;
  padding: 0;
  border-radius: 0;
  position: fixed;
  bottom: 40px;
  right: 40px;
  cursor: pointer;
}
.to-top-button:focus {
  outline: none;
}
.to-top-button__inner {
  background: #7d7bff;
  border-radius: 100%;
  border: 1px solid #7d7bff;
  box-shadow: 1px 3px 8px 1px rgba(0, 0, 0, 0.12);
  height: 40px;
  width: 40px;
  padding: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  transition: width 0.1s ease-in-out, border-radius 0.1s ease-out 0.1s;
  align-items: center;
  outline: none;
  cursor: pointer;
}
.to-top-button__inner__icon {
  position: absolute;
  height: 14px;
  right: 14.75px;
  top: 13px;
}
.to-top-button__inner__text {
  opacity: 0;
  visibility: hidden;
  margin: 0;
  width: 0;
  padding: 0;
  color: transparent;
  box-sizing: border-box;
}
.to-top-button:hover {
  height: 40px;
}
@media only screen and (min-width: 601px) {
  .to-top-button:hover .to-top-button__inner {
    width: 116px;
    height: 40px;
    border-radius: 24px;
    border: 1px solid #7d7bff;
    transition: width 0.1s ease-in-out 0.1s, border-radius 0.1s ease-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .to-top-button:hover .to-top-button__inner__text {
    transition-delay: 0.4s;
    transition: 0.4s ease-in;
    opacity: 1;
    visibility: visible;
    width: auto;
    padding-left: 8px;
    box-sizing: border-box;
    white-space: nowrap;
    color: white;
  }
}

