$primary-base: #7d7bff;
$primary-light: #c9c3ff;
$primary-dark: #322679;

$secondary-base: #ac3d50;
$secondary-light: #d8a2ab;
$secondary-dark: #6e0d1d;

$gray-white: #fff;
$gray-black: #222;
$gray-offWhite: #fdfeff;

.to-top-button {
  width: fit-content;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-color: transparent;
  border: none;
  background: transparent;
  margin: 0;
  z-index: 99;
  padding: 0;
  &:focus {
    outline: none;
  }
  border-radius: 0;
  position: fixed;
  bottom: 40px;
  right: 40px;
  cursor: pointer;

  &__inner {
    background: $primary-base;
    border-radius: 100%;
    border: 1px solid $primary-base;
    box-shadow: 1px 3px 8px 1px transparentize($color: #000000, $amount: 0.88);
    height: 40px;
    width: 40px;
    padding: 4px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: width 0.1s ease-in-out, border-radius 0.1s ease-out 0.1s;
    align-items: center;
    outline: none;
    cursor: pointer;
    &__icon {
      position: absolute;
      height: 14px;
      right: 14.75px;
      top: 13px;
    }
    &__text {
      opacity: 0;
      visibility: hidden;
      margin: 0;
      width: 0;
      padding: 0;
      color: transparent;
      box-sizing: border-box;
      // transition-delay: 0.4s;
    }
  }
  &:hover {
    height: 40px;
    @media only screen and (min-width: 601px) {
      .to-top-button__inner {
        width: 116px;
        height: 40px;
        border-radius: 24px;
        border: 1px solid $primary-base;
        transition: width 0.1s ease-in-out 0.1s, border-radius 0.1s ease-out;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        &__text {
          transition-delay: 0.4s;
          transition: 0.4s ease-in;
          // transition-property: opacity;
          opacity: 1;
          visibility: visible;
          width: auto;
          padding-left: 8px;
          box-sizing: border-box;
          white-space: nowrap;
          color: white;
        }
      }
    }
  }
}
