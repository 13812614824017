.toggle-wrapper {
  width: auto;
  height: auto;
}

.toggle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 99;
}
.toggle__input {
  display: none;
  opacity: 0;
  height: 0;
}
.toggle__button {
  position: absolute;
  height: 28px;
  border-radius: 100%;
  width: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #7d7bff;
  box-sizing: border-box;
  color: white;
  right: 3px;
  top: 2px;
  transition-property: all;
}
.toggle__button[toggle-on=true] {
  transition-duration: 0.3s;
  transform: translateX(0);
}
.toggle__button[toggle-on=false] {
  transition-duration: 0.3s;
  transform: translateX(-30px);
  background: #7d7bff;
}
.toggle__half {
  width: 32px;
  height: 32px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  box-sizing: border-box;
  color: #444;
  border: 1px solid #ddd;
}
.toggle__half .icon {
  z-index: 2;
}
.toggle__half.--off {
  border-radius: 16px 0 0 16px;
  border-right: none;
}
.toggle__half.--off .icon {
  transition: 0.3s;
}
.toggle__half.--off[is-active=true] .icon {
  color: white;
  transition: 0.3s;
}
.toggle__half.--on {
  border-radius: 0 16px 16px 0;
  border-left: none;
  color: white;
}

