@import "./../../vars.scss";
.tag {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: $gray-black;
  border: 1px solid currentColor;
  border-radius: 16px;
  height: 24px;
  padding: 0 8px;
  margin-right: 8px;
  font-size: 14px;
  color: $gray-offWhite;
  width: auto;
  box-sizing: border-box;
  cursor: pointer;
}

.tag__tag-text {
  text-transform: uppercase;
  margin-right: 8px;
  font-weight: 500;
  line-height: 20px;
}

.tag--handle-hover:hover {
  cursor: pointer;
}
