.header {
  height: 100px;
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: transparent;
  margin-bottom: -80px;
  transition: 0.3s ease-in-out;
}

.header--light {
  color: rgb(19, 19, 19);
}

.header--dark {
  color: white;
}

.header__header-inner {
  height: 100%;
  width: 80vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__header-title {
  font-family: "IBM Plex Mono", "DM Sans", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 24px;
  margin: 0;
  text-transform: lowercase;
  text-align: center;
  color: inherit;
  letter-spacing: 1.2;
}

@media only screen and (max-width: 600px) {
  .header__header-title {
    font-size: 16px;
    white-space: nowrap;
  }

  .header__settings-button {
    display: none;
  }

  .header__header-inner {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90vw;
    .logo {
      display: none;
    }
  }

  .header {
    padding-top: 40px;
    height: auto;
    box-sizing: border-box;
  }
}

.header__settings-button {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 14px;
  width: 10%;
  letter-spacing: 4px;
  /* margin-left: 22vw; */
  margin: 0;
  padding: 0;
  text-align: right;
  color: inherit;
}

.header__settings-button:hover {
  text-decoration: underline;
  transition: 0.2s;
  cursor: pointer;
}
