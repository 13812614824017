.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100vw;
	overflow-wrap: break-word;
    overflow: hidden;
    position: relative;
}

.refresh-wrapper {
    width: 80vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 16px;
    padding-right: 8px;
    box-sizing: border-box;
}


.styled-button {
	height: 40px;
	width: auto;
	padding: 0 40px;
	border-radius: 8px;
	border: 1px solid;
	font-family: Helvetica, Arial, sans-serif;
	font-weight: bold;
	text-decoration: none;
    transition: 0.2s;
    box-sizing: border-box; 
    cursor: pointer;
	&:hover {
		transition: 0.2s;
	}
	&.secondary {
		background: white;
		color: #6973ff;
		border-color: #6973ff;
		&:hover {
			background: #6973ff;
			color: white;
		}
	}
	&.give-space {
		margin: 40px;
	}
}


@media only screen and (max-width: 600px) {
    .refresh-wrapper {
        padding-right: 0;
        width: 90vw;
    }
}