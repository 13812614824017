$primary-base: #7d7bff;
$primary-light: #c9c3ff;
$primary-dark: #322679;

$secondary-base: #ac3d50;
$secondary-light: #d8a2ab;
$secondary-dark: #6e0d1d;

$gray-white: #fff;
$gray-black: #222;
$gray-offWhite: #fdfeff;
