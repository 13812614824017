@import "./../../vars";

.toggle-wrapper {
  width: auto;
  height: auto;
}

.toggle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 99;

  &__input {
    display: none;
    opacity: 0;
    height: 0;
  }
  &__button {
    position: absolute;
    height: 28px;
    border-radius: 100%;
    width: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $primary-base;
    box-sizing: border-box;
    color: white;
    // animation: slide-on;
    // animation-duration: 0.3s;
    right: 3px;
    top: 2px;
    transition-property: all;
    &[toggle-on="true"] {
      transition-duration: 0.3s;
      // animation-direction: normal;
      transform: translateX(0);
    }
    &[toggle-on="false"] {
      // animation-direction: reverse;
      transition-duration: 0.3s;
      transform: translateX(-30px);
      background: $primary-base;
      // left: 2px;
    }
  }
  &__half {
    width: 32px;
    height: 32px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    box-sizing: border-box;
    color: #444;
    border: 1px solid #ddd;
    .icon {
      z-index: 2;
    }
    &.--off {
      border-radius: 16px 0 0 16px;
      border-right: none;
      .icon {
        transition: 0.3s;
      }
      &[is-active="true"] {
        .icon {
          color: white;
          transition: 0.3s;
        }
      }
    }

    &.--on {
      border-radius: 0 16px 16px 0;
      border-left: none;
      color: white;
    }
  }
}

// @keyframes slide-on {
// 	0% {
// 		transform: translateX(-100%);
// 	}

// 	100% {
// 		transform: tranlateX(0);
// 	}
// }
