.controls {
	width: 80vw;
	height: auto;
	padding: 120px 0 80px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
    .controls {
        padding: 120px 0 40px 0;
    }
}