/* @import 'antd/dist/antd.css'; */

.App {
  text-align: center;
  overflow-x: hiden;
  width: 100vw;
  max-width: 100vw;
}

.App--light {
  background: linear-gradient(
    315deg,
    white 0%,
    white 33%,
    white 66%,
    white,
    90%,
    rgb(250, 249, 249) 100%
  );
}

.App--dark {
  background: linear-gradient(
    315deg,
    rgb(37, 37, 37) 0%,
    rgb(37, 37, 37) 33%,
    rgb(37, 37, 37) 66%,
    rgb(37, 37, 37),
    90%,
    rgb(63, 63, 63) 100%
  );
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
