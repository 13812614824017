.button {
  height: 40px;
  width: auto;
  padding: 8px 16px;
  font-family: helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 24px;
  border: 1px solid;
}

