@import "./../../vars.scss";

.query-params {
	width: 480px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	border: none;
}



.query-params__label {
	font-family: Helvetica, sans-serif;
	font-size: 16px;
	font-weight: 600;
	margin-left: 16px;
	margin-right: 0;
}

.input-wrapper {
	width: 100%;
	min-width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	input {
	}
	&__buttons {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		.button {
			margin-left: 16px;
		}
	}
}

.query-params__input-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	height: auto;
	margin-top: 8px;
	box-sizing: border-box;
}

.query-params__input {
	height: 40px;
	background: none;
	width: auto;
	width: -webkit-fill-available;
	border-radius: 24px;
	padding: 0 8px 0 16px;
	outline: none;
	font-family: Helvetica, sans-serif;
	font-size: 16px;
	box-sizing: border-box;
	border: 1px solid $gray-black;
	&.--dark {
			border-color: $gray-offWhite;
			color: $gray-offWhite;

	}
}

.query-params__keywords {
	height: auto;
	display: flex;
	padding-left: 16px;
	margin-top: 8px;
	flex-wrap: wrap;
	width: 100%;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
    .query-params {
        width: 90vw;
    }
    .input-wrapper {
        flex-direction: column;
        align-items: flex-end;
        &__buttons {
            margin-top: 16px;
            flex-direction: row-reverse;
            .button {
                margin-left: 0;
                margin-right: 8px;
            }
        }
    }
}